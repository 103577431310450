<template>
    <div class="home">
        <div v-wechat-title="this.title">
            <van-sticky :offset-top="0">
                <div class="page_top">
                    <div class="date_choose">
                        <!-- <i class="icon iconfont iconarrow-left-filling" @click="subtractDate"></i> -->
                        <van-icon name="arrow-left" @click="subtractDate" />
                        <span class="date_info" @click="chooseCheckDay">{{ date || '请选择' }}</span>
                        <van-icon name="arrow" @click="addDate" />
                        <!-- <i class="icon iconfont iconarrow-right-filling" @click="addDate"></i> -->
                    </div>
                    <van-search class="search_div" input-align="center" left-icon="" background="#F8F7FC"
                        @search="qrypage = 1; reLoad()" v-model="keyword" placeholder="预约编号/患者姓名/手机号码">
                        <van-icon name="close" @click="() => keyword = ''" v-if="keyword" />
                    </van-search>
                </div>
            </van-sticky>


            <van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" :show-confirm="false"
                @confirm="onConfirmCalendar" />

            <van-tabs v-model="qrystatus" :offset-top="62" @change="changeTab" class="status_div" color="#E69F12"
                title-active-color="#E69F12" sticky swipeable>

                <van-tab title="门诊预约" name="0">

                </van-tab>

                <van-tab title="理疗预约" name="1">

                </van-tab>

                <van-tab title="体检预约" name="3">

                </van-tab>
                <div class="treat_main_list" :class="{ 'bai': count <= 0 }">
                    <van-list v-model="listLoading" :finished="finished" @load="reLoad" :offset="30"
                        :immediate-check="false" v-if="list.length > 0" finished-text="加载完成">
                        <div class="one" v-for="item in list" :key="item.serviceid">

                            <div class="name_box">
                                <div class="name">
                                    <span class="username">{{ item.username }}</span>
                                    <span v-if="item.seetypename == '复' || item.seetypename == 'W'" class="seetypename_f">{{
                                        item.seetypename }}</span>
                                    <span v-if="item.seetypename == '初'" class="seetypename_c">{{ item.seetypename }}</span>
                                </div>
                                <div class="ex_box">

                                    <div class="appointmentnum" v-if="item.appointmentnum">{{ item.appointmentnum }}</div>
                                    <div class="appointment" @click="medical(item.userid, item.customerid)">
                                        <span>查看病历</span> <i class="icon iconfont iconsanjiaoyou"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="name_box line">
                                <div class="flex">
                                    <div class="user_info">{{ item.sex }}</div>
                                    <div class="user_info">{{ item.age }}岁</div>
                                    <div class="user_info">{{ item.phone }}</div>
                                </div>
                                <div class="flex">
                                    <div v-if="item.appointmentseq && item.wait != 0" @click="operate(3, item.recordid)"
                                        class="mini_bt">上移</div>
                                    <div v-if="item.appointmentseq && (item.seestatus == 1 || item.seestatus == 0)"
                                        @click="operate(4, item.recordid)" class="mini_bt">下移</div>
                                </div>

                            </div>
                            <div class="name_box">
                                <div>预约时间</div>
                                <div>{{ item.time ? item.time : '无' }}</div>
                            </div>
                            <div class="name_box">
                                <div>预约医生</div>
                                <div>{{ item.dispname }}</div>
                            </div>
                            <div class="name_box">
                                <div>等待人数</div>
                                <div>{{ item.wait }}</div>
                            </div>
                            <div class="name_box" v-if="qrystatus != 0">
                                <div>就诊状态</div>
                                <div>{{ item.execstatusname }}</div>
                            </div>
                            <div class="name_box" v-if="item.appointmentseq">
                                <div>报到号</div>
                                <div>{{ item.appointmentseq }}</div>
                            </div>
                            <div class="flex top10">
                                <van-button v-if="item.seestatus == 1" @click="operate(2, item.recordid)" round
                                    type="warning">取消报到</van-button>
                                <van-button v-if="item.seestatus == 0" @click="operate(1, item.recordid)" round
                                    type="success">报到</van-button>
                                <van-button v-if="item.seestatus == 1 || item.seestatus == 0"
                                    @click="detailCtrl(item.recordid)" round type="success">预录入</van-button>
                                <van-button v-if="qrystatus == 0 && (item.seestatus == 1 || item.seestatus == 0)"
                                    @click="editDoctor(item.recordid, item.dispname)" round type="success">修改医生</van-button>
                            </div>
                        </div>
                    </van-list>
                    <div class="has_no_data" v-else>
                        当天无预约数据
                    </div>
                </div>
            </van-tabs>
            <van-dialog v-model="show" showCancelButton title="修改医生" close-on-popstate
                @confirm="subDoctor(); treatitem = {}" @cancel="treatitem = {}" :before-close="onBeforeClose">
                <van-cell :border="false" title="当前医生：" :value="doctor" />
                <van-cell :border="false" title="修改医生：" @click="showPicker = true" is-link :value="treatitem.doctor" />
            </van-dialog>
            <van-popup v-model="showPicker" round position="bottom">
                <van-picker title="请选择医生" show-toolbar :columns="doctorList" :default-index="0" @cancel="showPicker = false"
                    @confirm="onConfirmDoctor"><template #option="item">{{ item.doctor }}</template>
                </van-picker>
            </van-popup>
        </div>
    </div>
</template>

<script>
import { wxappointmentcheckininfoqry, wxappointmentopr, wxtriagedoctorroomqry } from '@/api/medical'
import { mapGetters } from 'vuex'
// import Vue from 'vue';
import { Toast, Calendar } from 'vant';
import Vue from 'vue';
import dayjs from 'dayjs';

Vue.use(Calendar);

export default {
    computed: {
        ...mapGetters([
            'username', 'clinicid', 'isrefresh', 'isclose'
        ]),
    },
    data() {
        return {
            qrystatus: '0',
            list: [],
            // 无限加载
            listLoading: false,
            finished: false,
            keyword: '',
            wait: 0,
            count: 0,
            execute: 0,
            finish: 0,
            minDate: new Date(2020, 0, 1),
            defaultDate: new Date(),
            date: '',
            showCalendar: false,
            title: '报到分诊台',
            show: false,
            doctor: '',
            recordid: '',
            showPicker: false,
            doctorList: [],
            treatitem: {},
            noDoctor: true,
            qrypage: 1
        }
    },
    mounted() {
        this.$store.dispatch('changeIsClose', '1')
        this.$store.dispatch('changeNav', '0')
        this.$store.dispatch('hideOrShowNav', false)
        this.getCurDate()
        Toast({
            message: '加载中...',
            type: 'loading',
            duration: 0
        })
        this.reLoad()
    },
    activated() {
        this.$store.dispatch('changeNav', '0')
        this.$store.dispatch('hideOrShowNav', false)
        console.log(this.isrefresh, this.listLoading, '----isrefresh')
        if (this.isrefresh == '1' && !this.listLoading) {
            this.$store.dispatch('changeIsRefresh', '0')
            this.date = dayjs().format('YYYY-MM-DD')
            this.qrystatus = 0
            this.keyword = '';
            this.qrypage = 1
            this.reLoad();
            this.show = false
        }
    },
    beforeRouteLeave(to, from, next) {
        console.log(to, from, 'treat-----')
        if (to.path == '/assignTreat') {
            from.meta.keepAlive = false

        } else if (to.path == '/triage') {
            to.meta.keepAlive = false
        }
        next()
    },
    methods: {
        medical(useuserid, usecustomerid) {
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/medicalPage',
                query: {
                    userid: useuserid,
                    customerid: usecustomerid,
                }
            })
        },
        goBack() {
            window.WeixinJSBridge.call('closeWindow')
        },
        getCurDate() {
            const nowDate = new Date();
            this.formatDate(nowDate)
        },
        addDate() {
            var d = new Date(this.date);
            d.setDate(d.getDate() + 1);
            this.formatDate(d)
            this.qrypage = 1
            this.reLoad()

        },
        subtractDate() {
            var d = new Date(this.date);
            d.setDate(d.getDate() - 1);
            this.formatDate(d)
            this.qrypage = 1
            this.reLoad()

        },
        formatDate(d) {
            const curDate = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                date: d.getDate(),
            }
            const newmonth = curDate.month >= 10 ? curDate.month : '0' + curDate.month
            const day = curDate.date >= 10 ? curDate.date : '0' + curDate.date
            this.date = curDate.year + '-' + newmonth + '-' + day
            this.defaultDate = new Date(this.date);
        },
        chooseCheckDay() {
            this.showCalendar = true
        },
        onConfirmCalendar(date) {
            this.showCalendar = false
            this.formatDate(date);
            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.qrypage = 1
            this.reLoad()
        },
        changeRouter(url) {
            if (url) {
                this.$router.push(url)
            }
        },

        changeTab() {
            if (this.isload) {
                return
            }

            Toast({
                message: '加载中...',
                type: 'loading',
                duration: 0
            })
            this.qrypage = 1
            this.reLoad()
        },

        reLoad() {

            if (this.clinicid == null || this.clinicid == '' || this.clinicid == undefined
                || this.username == null || this.username == '' || this.username == undefined) {
                Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '未登录或未选择诊所，页面关闭',
                });
                let second = 1;
                const timer = setInterval(() => {
                    second--;
                    if (!second) {
                        clearInterval(timer);
                        // 手动清除 Toast
                        Toast.clear();
                        this.goBack()
                    }
                }, 1000);
                return;

            }

            this.listLoading = true


            let data = {
                username: this.username,
                qrydate: this.date,
                keywords: this.keyword,
                clinicid: this.clinicid,
                qrytype: this.qrystatus,
                qrypage: this.qrypage
            }

            wxappointmentcheckininfoqry(data).then(response => {
                if (Toast) {
                    Toast.clear()
                }

                if (response.response_body && response.response_body.list) {
                    response.response_body.list.forEach((item) => {
                        item.phone = item.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
                    })

                    this.count = parseInt(response.response_body.num)
                    if (this.qrypage == 1) {
                        this.list = response.response_body.list
                        document.querySelectorAll('.home').forEach(function (scrollContainer) {
                            scrollContainer.scrollTop = 0;
                        });
                    } else {
                        this.list = [...this.list, ...response.response_body.list]
                    }
                    if (response.response_body.list.length < 10) {
                        this.finished = true;
                    } else {
                        this.finished = false;
                    }


                }
                this.qrypage++
                this.listLoading = false
            })



        },

        // 预录入
        detailCtrl(recordid) {
            this.$store.dispatch('changeIsRefresh', '0')
            this.$router.push({
                path: '/appointmentDetail',
                query: {
                    recordid: recordid

                }
            })
        },
        // 1：报到，2：取消报到，3：上移，4：下移，5：修改医生
        operate(oprtype, recordid, doctorid) {
            let sub_data = {
                username: this.username,
                recordid: recordid,
                oprtype: oprtype,
                doctorid: doctorid
            }
            if (oprtype == 5 && !doctorid) {
                this.show = true
                Toast.fail('请选择医生');
                this.noDoctor = true
                return
            }
            this.noDoctor = false
            wxappointmentopr(sub_data).then(response => {

            }).then(() => {
                Toast.success('操作成功');
                setTimeout(() => {
                    this.qrypage = 1
                    this.reLoad()
                }, 2000)

            })
                .catch(() => {

                });
        },
        editDoctor(recordid, doctorname) {
            this.show = true,
                this.recordid = recordid,
                this.doctor = doctorname
            this.getdoctorroomList()
        },
        onConfirmDoctor(val) {

            this.treatitem.doctor = val.doctor

            this.treatitem.username = val.username

            this.showPicker = false
        },
        subDoctor() {
            this.operate(5, this.recordid, this.treatitem.username)
        },
        onBeforeClose(action, done) {
            // new Promise((resolve) => {
            if (action === 'confirm') {
                console.log(this.noDoctor);
                if (this.noDoctor) {
                    done(false);
                }
                done(true);
            } else {
                // 拦截取消操作
                done(true);
            }
            //  })

        },
        // 查询医生
        getdoctorroomList() {
            let data = {
                username: this.username,
                clinicid: sessionStorage.ClinicId
            }
            wxtriagedoctorroomqry(data).then(response => {
                this.doctorList = response.response_body.doctorlist
            })
        },

    },
}
</script>

<style lang="scss" scoped>
.home {

    background: #fafafa;
    height: 100vh;
    overflow: auto;
}

.van-info {
    top: 2px;
    right: -8px;
}

.van-search__content {
    .van-cell {
        background-color: unset !important;
        border-radius: 0.50667rem;
        padding: 0 !important;
    }
}

.bai {
    background-color: #ffffff;
}

.btnimg {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: 200px;

    img {
        width: 100%;
        height: 100%;
    }
}

.treat_main_list .one {
    background: linear-gradient(180deg, rgba(207, 140, 87, 0.2) 0%, rgba(255, 249, 244, 0) 20%);
}

.photo {
    width: 100px;
    height: 120px;
    border-radius: 10px;
    margin-right: 10px;
}

// .one{
//     display: flex;
// }
.name {
    font-size: 18px;
    font-weight: 600;
    display: flex;
}

.surplusnum {
    color: red;
}

.user_info {
    color: #999995;
    margin-right: 4px;
    line-height: 20px;
}

.date_choose {
    border: 0.02667rem solid #dfdcdc;
    padding: 10px 2px;
    width: 150px;
    border-radius: 3.48rem
}

.search_div {
    width: calc(100% - 160px);
}

:deep .van-field__control--center {
    text-align: left;
}

:deep .van-search__content {
    padding-left: 0rem;
}

.seetypename_f {
    color: rgb(230, 159, 18);
    padding: 2px;
    border-radius: 5px;
    border: rgb(230, 159, 18) solid 1px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
}

.seetypename_c {
    color: rgb(133, 142, 228);
    padding: 2px;
    border-radius: 5px;
    border: rgb(133, 142, 228) solid 1px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
}

.ex_box {
    display: flex;
    align-items: center;
    // color: rgb(230, 159, 18);
    color: #999999;
    justify-items: center;

    i {
        color: #BBBBBB;
        font-style: normal;
        font-size: 0.64rem;
        margin-left: 0.05333rem;

    }
}

.appointmentnum {
    color: rgb(230, 159, 18);
    background-color: #fbeec8;
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.name_box {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mini_bt {
    color: #5C7BFF;
    margin-right: 5px;
}

.line {
    border-bottom: 1px solid #dfdcdc;
    padding-bottom: 8px;
}

:deep .van-button--round {
    background-color: #CF8C57;
    width: 100px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    color: #fff;
    border-radius: 21px;
    margin: 0px 5px;
}

:deep .van-button--warning {
    background-color: #bfbaba !important;
    border: 0.02667rem solid #d3d3d3
}

.top10 {
    margin-top: 10px;
}

:deep .van-cell__right-icon {
    line-height: 25px;
}

:deep .van-cell {
    background-color: #ffffff;
    padding: 10px 20px;
}

.appointment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.username {
    max-width: 3rem;
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>